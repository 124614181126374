export default class Hamburger {
  constructor() {
    this.setup();
  }
  setup() {
    const hamburgerMenu = document.getElementById('js-menu-btn');
    const root = document.documentElement;
    const pageLink = document.querySelector('.header__nav').querySelectorAll('.anchor');

    if (hamburgerMenu) {
      hamburgerMenu.addEventListener('click', (e) => {
        const target = e.currentTarget;
        if (target.classList.contains('-open')) {
          target.classList.remove('-open');
          target.classList.add('-close');
          root.classList.remove('menu-open');
        } else {
          target.classList.remove('-close');
          target.classList.add('-open');
          root.classList.add('menu-open');
        }
      });
      pageLink.forEach((e) => {
        e.addEventListener('click', () => {
          hamburgerMenu.classList.remove('-open');
          hamburgerMenu.classList.add('-close');
          root.classList.remove('menu-open');
        });
      });
    }
  }
}
