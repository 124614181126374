import 'svgxuse';
import 'focus-visible';
import ObjectFit from './modules/ObjectFit';
import MatchMedia from './modules/MatchMedia';
import AddNoopener from './modules/AddNoOpener';
import Loading from './modules/Loading';
import Smoothscroll from './modules/SmoothScroll';
import ObserveTarget from './modules/ObserveTarget';
import Hamburger from './modules/Hamburger';
import Accordion from './modules/Accordion';
import Tab from './modules/Tab';
import Modal from './modules/Modal';
import FvSlider from './modules/FvSlider';
import './modules/scrollEvent';

class Index {
  constructor() {
    new ObjectFit();
    new MatchMedia();
    new AddNoopener();
    new Loading();
    new Smoothscroll();
    new ObserveTarget();
    new Hamburger();
    new Accordion();
    new Tab();
    new Modal();
    new FvSlider();
  }
}

export default new Index();
