export default class Tab {
  constructor() {
    this.setup();
  }
  setup() {
    const tabs = document.getElementsByClassName('tab');
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].addEventListener('click', tabSwitch, false);
    }

    function tabSwitch() {
      // 引数で指定したセレクターと一致する直近の祖先要素を取得
      const ancestorEle = this.closest('.js-tab');
      // タブのclassの値を変更
      ancestorEle.getElementsByClassName('-active')[0].classList.remove('-active');
      this.classList.add('-active');
      // コンテンツのclassの値を変更
      ancestorEle.getElementsByClassName('-show')[0].classList.remove('-show');
      const groupTabs = ancestorEle.getElementsByClassName('tab');
      const arrayTabs = Array.prototype.slice.call(groupTabs);
      const index = arrayTabs.indexOf(this);
      ancestorEle.getElementsByClassName('tab-pane')[index].classList.add('-show');
    }
  }
}
