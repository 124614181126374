import MicroModal from 'micromodal';

export default class Modal {
  constructor() {
    MicroModal.init({
      openClass: '-open',
      disableScroll: true,
      disableFocus: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true
    });
  }
}
