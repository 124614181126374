/**
 * @example
 * import MediaQuery from '../utils/MediaQuery';
 * md未満の場合に実行
 * MediaQuery.matches('md', (matches) => {
 *   if (matches) {
 *     func();
 *   } else {
 *     func();
 *   }
 * });
 * 1000px（任意の数値）未満の場合に実行
 * MediaQuery.matches(1000, (matches) => {
 *   if (matches) {
 *     func();
 *   } else {
 *     func();
 *   }
 * });
 */

// src/scss/global/variables/_breakpoints.scssと合わせる
const list = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

export default class MediaQuery {
  static matches(query, layoutChangedCallback) {
    let mediaQuery = `(max-width: ${list[query] - 1}px)`;

    if (typeof query === 'number') {
      mediaQuery = `(max-width: ${query - 1}px)`;
    }

    const mql = window.matchMedia(mediaQuery);

    const listener = (event) => {
      layoutChangedCallback(event.matches);
    };

    window.addEventListener('load', () => {
      layoutChangedCallback(mql.matches);
    });
    mql.addEventListener('change', () => {
      layoutChangedCallback(mql.matches);
    });
    const uninstall = () => {
      mql.removeEventListener(listener);
    };
    return uninstall;
  }
}
