const root = document.documentElement;

// scroll
let ticking = false;
let position = 0;
function onScroll() {
  if (position < window.innerHeight * 0.75) {
    root.classList.remove('view-contents');
  } else {
    root.classList.add('view-contents');
  }
}

window.addEventListener('scroll', function () {
  position = window.pageYOffset;
  if (!ticking) {
    window.requestAnimationFrame(function () {
      onScroll(position);
      ticking = false;
    });
    ticking = true;
  }
});
