import {slideDown, slideUp, slideStop} from 'slide-anim';

export default class Accordion {
  constructor() {
    this.setup();
  }
  setup() {
    const acc = document.getElementsByClassName('js-accordion');
    const speed = 600;

    for (let i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', (e) => {
        const target = e.currentTarget;
        const hideContent = target.nextElementSibling;
        if (target.classList.contains('-open')) {
          target.classList.remove('-open');
          target.classList.add('-close');
          slideStop(hideContent);
          slideUp(hideContent, {
            duration: speed
          });
        } else if (target.classList.contains('default-open')) {
          target.classList.add('-close');
          slideUp(hideContent, {
            duration: speed
          }).then(() => {
            target.classList.remove('default-open');
          });
        } else {
          target.classList.remove('-close');
          target.classList.add('-open');
          slideStop(hideContent);
          slideDown(hideContent, {
            duration: speed
          });
        }
      });
    }
  }
}
