import MediaQuery from '../utils/MediaQuery';

export default class MatchMedia {
  constructor() {
    this.setup();
  }
  setup() {
    const root = document.documentElement;
    MediaQuery.matches('md', (matches) => {
      if (matches) {
        root.classList.remove('view-pc');
        root.classList.add('view-sp');
      } else {
        root.classList.remove('view-sp');
        root.classList.add('view-pc');
      }
    });
  }
}
