import 'intersection-observer';

export default class ObserveTarget {
  constructor() {
    const targetElements = document.querySelectorAll('.js-observeTarget, .js-observeTarget-event');

    const observeVisible = () => {
      if (!targetElements.length) return;

      const handleObserve = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.setAttribute('data-visible', 'true');
          }
        });
      };

      const observerOptions = {
        root: null,
        rootMargin: '0px 0px -1%',
        threshold: 0
      };

      const observer = new IntersectionObserver(handleObserve, observerOptions);

      for (let i = 0; i < targetElements.length; i++) {
        targetElements[i].setAttribute('data-visible', 'false');
        observer.observe(targetElements[i]);
      }
    };

    document.addEventListener('DOMContentLoaded', observeVisible, false);
  }
}
