import Splide from '@splidejs/splide';

export default class FvSlider {
  constructor() {
    this.setup();
  }
  setup() {
    const elm = document.querySelector('.l-fv__slider');
    if (elm) {
      const splide = new Splide(elm, {
        rewind: true,
        type: 'fade',
        keyboard: true,
        drag: true,
        updateOnMove: true,
        pagination: false,
        arrows: true,
        arrowPath: 'M17.2665 26L16 24.7803L21.483 19.5L16 14.2197L17.2665 13L24 19.5L17.2665 26Z',
        autoplay: 'play',
        speed: 1000,
        interval: 8000,
        cover: true
      });

      splide.mount();
    }
  }
}
