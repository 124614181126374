export default class AddNoopener {
  constructor() {
    this.setup();
  }
  setup() {
    var aTags = [].slice.call(document.getElementsByTagName('a'));

    var userAgent = window.navigator.userAgent.toLowerCase();
    var isIE = ~userAgent.indexOf('msie') || ~userAgent.indexOf('trident');

    if (!isIE) {
      aTags.forEach(function (el) {
        if (el.target === '_blank') {
          var rels = el.rel.split(' ');
          if (!~rels.indexOf('noopener')) {
            rels.push('noopener');
            el.setAttribute('rel', rels.join(' ').trim());
          }
          if (!~rels.indexOf('noreferrer')) {
            rels.push('noreferrer');
            el.setAttribute('rel', rels.join(' ').trim());
          }
        }
      });
    }
  }
}
