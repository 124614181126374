export default class Loading {
  constructor() {
    const root = document.documentElement;
    window.addEventListener('DOMContentLoaded', function () {
      root.classList.add('domloaded');
    });
    window.addEventListener('load', function () {
      root.classList.add('loaded');
    });
  }
}
